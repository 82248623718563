import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import _, { isEmpty } from "lodash";
import { Button, Typography } from "@mui/material";
import ButtonComponent from "../Button";
import { SellOutlined, RedeemOutlined } from '@mui/icons-material';
import Card from "../Card";
import GiftSmallIcon from "../icons/Gift.svg";
import CalendarIcon from "../icons/Calendar.svg";
import { Price } from "../PriceLine";
import UnlockRewardModal from "../UnlockRewardModal";
import moment from "../../utils/moment-timezone-with-data";
import { startNewOrder } from "../../store/order/actions";
import { selectGift } from "../../store/giftRedeem/actions";
import NewOrPreviousOrderModal from "../NewOrPreviousOrderModal";
import { getParams, navigateTo, setParams } from "../../utils/location";

export default function CouponBatches({
  T,
  appStyles,
  currencySymbol,
  onChange,
  saveOrder,
  user,
  order: { 
    servingOptionType,
    branchId,
    couponsUncheckedByUser,
    couponsCheckedByUser,
    checkoutResponse,
    ...order
  },
  pageContext: {
    businessAppConfiguration,
    pointBankCoupons,
    menuData,
    servingOptions,
    branches,
    location,
  },
  batches,
  couponInstances,
  couponsBatchIndex,
}) {
  const dispatch = useDispatch();

  const batchesData = batches || _.get(checkoutResponse, "couponBatchesResponse.batches", []);
  const couponInstancesData = couponInstances || _.get(checkoutResponse, "couponBatchesResponse.couponInstances", []);
  const couponsBatchIndexData = couponsBatchIndex || _.get(order, "couponsBatchIndex", []);

  const isCouponAvaialbleForGroup = (couponInstance) => {
    const { groupIds, notGroupIds } = couponInstance;
    const userGroupIds = _.get(user, "loyaltyProfile.data.groupIds");

    const isAvailable = Boolean(
      (_.isEmpty(groupIds) ||
        _.intersection(groupIds, userGroupIds).length) &&
        (!userGroupIds || !_.intersection(notGroupIds, userGroupIds).length),
    );

    return isAvailable;
  };

  const rewardPointsBalance = _.get(user, 'loyaltyProfile.data.rewardPointBalance.balance')
 
  const isAvailablePointBankCoupon = (pointBankCoupon) => rewardPointsBalance - (pointBankCoupon.price || 0) >= 0;

  const [availablePointBankCoupons, lockedPointBankCoupons] = _.partition(
    _.filter(pointBankCoupons, isCouponAvaialbleForGroup),
    isAvailablePointBankCoupon,
  );

  const isUnlockRewardEnabled = _.get(businessAppConfiguration, "enableUnlockRewardsFlow");

  const [giftSelected, setGiftSelected] = useState(null);
  const [unlockRewardModalOpen, setUnlockRewardModalOpen] = useState(false);

  const limit = 10;

  const { couponInstanceIds, couponInstanceIdsToDiscount } =
    couponsBatchIndexData > -1 && batchesData[couponsBatchIndexData]
      ? batchesData[couponsBatchIndexData]
      : { couponInstanceIds: [], couponInstanceIdsToDiscount: [] };

  const [startNewOrderModalOpen, setStartNewOrderModalOpen] = useState(false);
  const [giftToRedeem, setGiftToRedeem] = useState(null);

  const orderSelectedServingOption =
    _.find(servingOptions, { type: servingOptionType }) || {};
  const orderSelectedBranch = _.find(branches, { id: branchId }) || {};

  const handleStartNewOrder = () => {
    dispatch(startNewOrder());
    handleStartContinueOrder();
  }

  const handleStartContinueOrder = () => {
    dispatch(selectGift(giftToRedeem, menuData));
  };

  const pointBankCouponIds = useMemo(() => _.map(
    pointBankCoupons,
    ({ couponId }) => couponId
  ), [pointBankCoupons]);

  const pointBankCouponGifts = useMemo(() => _.take(
    _.reverse(
      _.filter(user.gifts, (gift) =>
        _.includes(pointBankCouponIds, _.get(gift, "couponInstance.couponId"))
      )
    ),
    3
  ), [user.gifts, pointBankCouponIds]);

  return (
    <Card appStyles={appStyles}>
      {!isEmpty(batchesData) && (
        <>
          <Card.Title
            light
            appStyles={appStyles}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <GiftSmallIcon style={{ width: "24px", height: "24px" }} />
            {T("Rewards")}
          </Card.Title>
          <Card.Content>
            <Card.Content.CheckboxGroup
              name={T("Rewards")}
              limit={limit < 0 ? undefined : limit}
              checked={couponInstanceIds}
              onChange={(checked) => onChange({ checked })}
              appStyles={appStyles}
            >
              {_.map(couponInstancesData, (couponInstance) => (
                <Card.Content.CheckboxOption
                  iconSize={16}
                  iconInnerSize={16}
                  pointColor={appStyles.accentColor}
                  value={couponInstance.id}
                  key={couponInstance.id}
                >
                  <span style={{ paddingTop: 4, width: "100%" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{T(couponInstance.coupon.title)}</span>
                      <Price
                        discount
                        value={getDiscountAmount(
                          couponInstance.id,
                          couponInstanceIdsToDiscount
                        )}
                        currencySymbol={currencySymbol}
                      />
                    </span>
                    <span>
                      <small style={{ color: "#555" }}>
                        {T(couponInstance.coupon.description)}
                      </small>
                    </span>
                    <br />
                    <span>
                      <small style={{ color: "#555" }}>
                        {couponInstance.expirationDate && (
                          <span>
                            <CalendarIcon />
                            {` ${T("Valid until:")} ` +
                              moment(
                                new Date(couponInstance.expirationDate)
                              ).format(
                                appStyles.giftsExpirationDateFormat ||
                                  "MMM Do YY"
                              )}
                          </span>
                        )}
                      </small>
                    </span>
                  </span>
                </Card.Content.CheckboxOption>
              ))}
            </Card.Content.CheckboxGroup>
          </Card.Content>
        </>
      )}
      {pointBankCouponGifts && !_.isEmpty(pointBankCouponGifts) && (
        <>
          <Card.Title
            light
            appStyles={appStyles}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <RedeemOutlined />
            {T("Purchased gifts")}
          </Card.Title>
          <Card.Content>
            {_.map(pointBankCouponGifts, (gift) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "65%",
                  }}
                >
                  <span>{T(_.get(gift, "couponInstance.coupon.title"))}</span>
                </div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setGiftToRedeem(gift);
                    setStartNewOrderModalOpen(true);
                  }}
                >
                  {T("Redeem")}
                </Button>
              </div>
            ))}
            <ButtonComponent
              linkStyle
              appStyles={appStyles}
              style={{ minWidth: 100, marginTop: 16 }}
              centered
              onClick={() => {
                const giftsLinkTarget = setParams("/gifts", {
                  ...getParams(location),
                  backPath: "/order",
                });
                navigateTo(giftsLinkTarget);
              }}
            >
              <small>{T("More gifts")}</small>
            </ButtonComponent>
          </Card.Content>
          <NewOrPreviousOrderModal
            T={T}
            appStyles={appStyles}
            onChooseNewOrder={handleStartNewOrder}
            onChooseContinueOrder={handleStartContinueOrder}
            modalOpen={startNewOrderModalOpen}
            closeModal={() => setStartNewOrderModalOpen(false)}
            orderSelectedServingOption={orderSelectedServingOption}
            orderSelectedBranch={orderSelectedBranch}
          />
        </>
      )}
      {isUnlockRewardEnabled && !_.isEmpty(pointBankCoupons) && (
        <>
          <Card.Title
            light
            appStyles={appStyles}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <SellOutlined />
            {T("Gifts for points")}
          </Card.Title>
          <Card.Content>
            {_.map(
              [...availablePointBankCoupons, ...lockedPointBankCoupons],
              (pointBankCoupon) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "65%",
                    }}
                  >
                    <span>{T(pointBankCoupon.coupon.title)}</span>
                    <span style={{ fontSize: "0.9rem" }}>{`${
                      pointBankCoupon.price
                    } points`}</span>
                  </div>
                  <Button
                    disabled={lockedPointBankCoupons.includes(
                      pointBankCoupon
                    )}
                    variant="contained"
                    onClick={() => {
                      setGiftSelected(pointBankCoupon);
                      setUnlockRewardModalOpen(true);
                    }}
                  >
                    {T("Unlock")}
                  </Button>
                </div>
              )
            )}
            <Typography align="center" sx={{ padding: "10px 0" }}>
              {T(`You have ${rewardPointsBalance} points on your balance`)}
            </Typography>
          </Card.Content>
          {giftSelected && (
            <UnlockRewardModal
              modalOpen={unlockRewardModalOpen}
              closeModal={() =>
                setUnlockRewardModalOpen(!unlockRewardModalOpen)
              }
              T={T}
              appStyles={appStyles}
              giftSelected={giftSelected}
              onSuccessfulUnlock={saveOrder}
              shouldSyncGifts
            />
          )}
        </>
      )}
    </Card>
  );
}

const getDiscountAmount = (couponInstanceId, couponInstanceIdsToDiscount) => {
  const couponDiscount = _.find(
    couponInstanceIdsToDiscount,
    (cIToD) => cIToD.couponInstanceId === couponInstanceId,
  );
  return couponDiscount ? couponDiscount.discountAmount : null;
};
